import React, { useState } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import * as XLSX from 'xlsx'; // Import XLSX library

const FileUploadPage = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [deidentifiedPreview, setDeidentifiedPreview] = useState(null); // State for deidentified preview

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal for file preview
  const [isOriginalPreviewOpen, setIsOriginalPreviewOpen] = useState(false); // For original preview toggle
  const [isDeidentifiedPreviewOpen, setIsDeidentifiedPreviewOpen] = useState(false); // For deidentified preview toggle

  const storageAccountName = 'marwadata';
  const containerName = 'map';
  const sasToken = 'sp=racwl&st=2024-12-10T21:41:42Z&se=2025-12-11T05:41:42Z&spr=https&sv=2022-11-02&sr=c&sig=hkf%2Blr73gfauZ7QFhzpiXbeSil83AYE9YPmS7KFbpyg%3D';

  const containerClient = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
  ).getContainerClient(containerName);

  // Keywords for deidentifying columns (case-insensitive)
  const deidentificationKeywords = ['Patient', 'Location', 'Nurse' ];

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      if (selectedFile.type === 'text/csv') {
        // If the file is a CSV, preview it and prepare for deidentification
        const reader = new FileReader();
        reader.onloadend = () => {
          const csvText = reader.result;
          const rows = csvText.split('\n').map(row => row.split(','));
          setFilePreview(rows); // Show original CSV preview

          // Deidentify CSV data
          const deidentifiedRows = deidentifyCSV(rows);
          setDeidentifiedPreview(deidentifiedRows); // Show deidentified CSV preview
        };
        reader.readAsText(selectedFile); // Read the CSV as text
      } else if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        // If the file is an XLSX (Excel), preview it without deidentifying
        const reader = new FileReader();
        reader.onloadend = () => {
          const data = reader.result;
          const workbook = XLSX.read(data, { type: 'array' }); // Use 'array' instead of 'binary'
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const json = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Convert to 2D array
          setFilePreview(json); // Show original XLSX preview
          setDeidentifiedPreview(null); // No deidentification for XLSX
        };
        reader.readAsArrayBuffer(selectedFile); // Use ArrayBuffer for binary files
      } else {
        // Handle other file types (image, pdf, etc.) if needed
        setFilePreview(null);
        setDeidentifiedPreview(null);
      }
    }
  };

  // Deidentification logic for CSV file
  const deidentifyCSV = (csvData) => {
    const firstRow = csvData[0]; // Get the first row as headers
    const columnsToDeidentify = firstRow.map((col, index) => {
      return deidentificationKeywords.some(keyword =>
        col.toLowerCase().includes(keyword.toLowerCase())
      ) ? index : null;
    }).filter(index => index !== null);

    // Deidentify the first row (the headers) and the rest of the rows (the data)
    const updatedRows = csvData.map((row, rowIndex) => {
      return row.map((cell, colIndex) => {
        if (columnsToDeidentify.includes(colIndex)) {
          // If it's a header (rowIndex === 0), replace with 'DEIDENTIFIED'
          if (rowIndex === 0) {
            return 'DEIDENTIFIED';
          }
          return 'DEIDENTIFIED'; // Replace data cells with 'DEIDENTIFIED'
        }
        return cell;
      });
    });

    return updatedRows;
  };

  const uploadFile = async () => {
    if (!file) return;
    setUploading(true);
    setUploadError(null);
    setUploadSuccess(false);

    try {
      let fileToUpload = file;

      // If the file is a CSV, we need to deidentify it
      if (file.type === 'text/csv') {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const csvText = reader.result;
          let rows = csvText.split('\n').map(row => row.split(','));

          // Deidentify the CSV data
          rows = deidentifyCSV(rows);

          // Convert the deidentified data back to CSV format
          const deidentifiedCSV = rows.map(row => row.join(',')).join('\n');

          // Create a new Blob from the deidentified CSV text
          const blob = new Blob([deidentifiedCSV], { type: 'text/csv' });

          // Create a new file with the deidentified content
          fileToUpload = new File([blob], file.name, { type: 'text/csv' });

          // Proceed with uploading the deidentified file
          const blobClient = containerClient.getBlockBlobClient(fileToUpload.name);

          // Upload the deidentified file to Azure Blob Storage
          const uploadBlobResponse = await blobClient.uploadData(fileToUpload, {
            blobHTTPHeaders: { blobContentType: fileToUpload.type },
          });

          console.log('Upload successful', uploadBlobResponse);
          setUploadSuccess(true);
        };

        reader.readAsText(file); // Read the original file content
      } else {
        // For non-CSV files, directly upload the original file
        const blobClient = containerClient.getBlockBlobClient(file.name);

        // Upload the file to Azure Blob Storage
        const uploadBlobResponse = await blobClient.uploadData(file, {
          blobHTTPHeaders: { blobContentType: file.type },
        });

        console.log('Upload successful', uploadBlobResponse);
        setUploadSuccess(true);
      }
    } catch (error) {
      console.error('Error uploading file', error);
      setUploadError(error.message || error.toString());
    } finally {
      setUploading(false);
    }
  };

  const renderFilePreview = () => {
    if (!filePreview) return <p>No preview available for this file type.</p>;

    if (Array.isArray(filePreview)) {
      // For CSV or XLSX (2D array) preview, render as a table
      return (
        <table>
          <thead>
            <tr>
              {filePreview[0].map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filePreview.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    return <p>Unsupported file type for preview</p>;
  };

  const renderDeidentifiedPreview = () => {
    if (!deidentifiedPreview) return <p>No deidentified preview available.</p>;

    if (Array.isArray(deidentifiedPreview)) {
      // Render the deidentified preview as a table
      return (
        <table>
          <thead>
            <tr>
              {deidentifiedPreview[0].map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {deidentifiedPreview.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    return <p>Unsupported file type for preview</p>;
  };

  return (
    <div className="upload-wrapper">
      {/* Modal for Preview */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>File Preview</h3>
            {renderFilePreview()}
            <button onClick={() => setIsModalOpen(false)}>Close</button>
          </div>
        </div>
      )}

      <div className="upload-container">
        <input type="file" onChange={handleFileChange} />
        <button onClick={uploadFile} disabled={uploading}>Upload</button>

        {uploading && <p>Uploading...</p>}
        {uploadSuccess && <p>File uploaded successfully!</p>}
        {uploadError && <p>Error: {uploadError}</p>}
      </div>

      {/* Preview of Original Data */}
      <div className="preview-container">
        <h3 className="App-button" onClick={() => setIsOriginalPreviewOpen(!isOriginalPreviewOpen)}>
          Original File Preview: {isOriginalPreviewOpen ? 'Hide' : 'Show'} Preview
        </h3>
        {isOriginalPreviewOpen && renderFilePreview()}
      </div>

      {/* Preview of Deidentified Data */}
      <div className="preview-container">
        <h3 className="App-button" onClick={() => setIsDeidentifiedPreviewOpen(!isDeidentifiedPreviewOpen)}>
          Deidentified File Preview: {isDeidentifiedPreviewOpen ? 'Hide' : 'Show'} Preview
        </h3>
        {isDeidentifiedPreviewOpen && renderDeidentifiedPreview()}
      </div>
    </div>
  );
};

export default FileUploadPage;