import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { BlobServiceClient } from '@azure/storage-blob';

const AdminPage = () => {
  const { instance } = useMsal();
  const [blobs, setBlobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submittedFeedback, setSubmittedFeedback] = useState([]);

  // Your Azure Storage Container details
  const storageAccountName = 'marwadata';
  const containerName = 'map';
  const sasToken = 'sp=racwl&st=2024-12-10T21:41:42Z&se=2025-12-11T05:41:42Z&spr=https&sv=2022-11-02&sr=c&sig=hkf%2Blr73gfauZ7QFhzpiXbeSil83AYE9YPmS7KFbpyg%3D'; // Ensure SAS token is valid

  useEffect(() => {
    const fetchBlobs = async () => {
      try {
        const blobServiceClient = new BlobServiceClient(
          `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
        );
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobList = [];
        for await (const blob of containerClient.listBlobsFlat()) {
          blobList.push(blob);
        }
        setBlobs(blobList);
      } catch (error) {
        console.error('Error fetching blobs', error);
        setError('Failed to load blobs from the container');
      } finally {
        setLoading(false);
      }
    };

    fetchBlobs();

    const savedFeedback = localStorage.getItem('submittedFeedback');
    if (savedFeedback) {
      setSubmittedFeedback(JSON.parse(savedFeedback));
    }
  }, [instance]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="admin-page">
      <div className="admin-content-wrapper">
        {/* Left Section: Admin Content */}
        <div className="left-section">
          <h1>Admin Dashboard</h1>
          <p>Manage your Azure Storage container content here.</p>
          <h2>Blobs in Container: {containerName}</h2>
          <ul>
            {blobs.map(blob => (
              <li key={blob.name}>
                <strong>{blob.name}</strong> - {blob.properties.contentLength} bytes
              </li>
            ))}
          </ul>
        </div>

        {/* Right Section: Display Group Members */}
        <div className="right-section">
          <h2>All Users</h2>
        </div>

        {/* Bottom Section: Display Submitted Feedback */}
        <div className="bottom-section">
          <h2>Submitted Feedback</h2>
          {submittedFeedback.length > 0 ? (
            <ul>
              {submittedFeedback.map((feedbackItem, index) => (
                <li key={index}>
                  <p><strong>{feedbackItem.timestamp}</strong> - <em>{feedbackItem.userName}</em></p>
                  <p>{feedbackItem.text}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No feedback submitted yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;