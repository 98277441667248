import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import Papa from 'papaparse';
import { jsPDF } from 'jspdf';
import ChartAnnotation from 'chartjs-plugin-annotation';

// Register all necessary Chart.js components
Chart.register(...registerables, ChartAnnotation);

function ComponentA({ userInfo }) {
    const [csvContent, setCsvContent] = useState('');
    const [chartData, setChartData] = useState(null);
    const [chartDataTotalDraws, setChartDataTotalDraws] = useState(null);
    const [chartDataContamination, setChartDataContamination] = useState(null);  // For the new chart
    const [showRawContent, setShowRawContent] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('Upload CSV File');
    const [selectedChart, setSelectedChart] = useState('complianceRate'); // For dropdown

    useEffect(() => {
        const savedCsv = localStorage.getItem('csvContent');
        if (savedCsv) {
            setCsvContent(savedCsv);
            generateComplianceRateByDate(savedCsv);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('csvContent', csvContent);
    }, [csvContent]);

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            if (file.type === 'text/csv') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const text = event.target.result;
                    setCsvContent(text);
                    setUploadStatus('Upload Successful!');
                    generateComplianceRateByDate(text);
                };
                reader.readAsText(file);
            } else {
                alert('Please drop a valid CSV file.');
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const generateComplianceRateByDate = (csv) => {
        Papa.parse(csv, {
            header: true,
            complete: (results) => {
                const data = results.data;
        
                // Group data by Collection Date (month/year)
                const groupedData = data.reduce((acc, row) => {
                    const date = row['Collection Date'];
                    const dateObj = new Date(date);
                    const monthYear = `${String(dateObj.getMonth() + 1).padStart(2, '0')}/${dateObj.getFullYear()}`;
        
                    if (!acc[monthYear]) {
                        acc[monthYear] = [];
                    }
                    acc[monthYear].push(row);
                    return acc;
                }, {});
        
                // Initialize arrays for processing data
                const months = [];
                const complianceRates = [];
                const contaminationRates = [];
                const totalDraws = [];
                const steripathYesCounts = [];
                let totalContaminants = 0;  // To track total contaminants across all months
                let totalTotalDraws = 0;    // To track total draws across all months
        
                Object.keys(groupedData).forEach((monthYear) => {
                    const monthData = groupedData[monthYear];
                    const totalCount = monthData.reduce((acc, row) => {
                        const count = parseInt(row['Count']) || 0;
                        return acc + count;
                    }, 0);
        
                    // Count Steripath YES values (1 is YES)
                    const steripathYesCount = monthData.reduce((acc, row) => acc + parseInt(row['Steripath YES'] || 0), 0);
        
                    // Count contaminants (where "Contaminant ? " is "yes" or "1")
                    const contaminantsCount = monthData.reduce((acc, row) => {
                        const contaminant = row['Contaminant ? ']?.toLowerCase() === 'yes' || row['Contaminant ? '] === '1';
                        return contaminant ? acc + 1 : acc;
                    }, 0);
        
                    // Calculate contamination rate for this month (Contaminants / Total Draws)
                    const contaminationRate = totalCount > 0 ? (contaminantsCount / totalCount) * 100 : 0;
        
                    // Calculate compliance rate for this month (Steripath YES / Total Draws)
                    const complianceRate = totalCount > 0 ? (steripathYesCount / totalCount) * 100 : 0;
        
                    months.push(monthYear);
                    contaminationRates.push(contaminationRate);
                    complianceRates.push(complianceRate);
                    totalDraws.push(totalCount);
                    steripathYesCounts.push(steripathYesCount);
        
                    // Add to totals for global average calculation
                    totalContaminants += contaminantsCount;
                    totalTotalDraws += totalCount;
                });
        
                // Calculate the average contamination rate across all months
                const averageContaminationRate = totalTotalDraws > 0 ? (totalContaminants / totalTotalDraws) * 100 : 0;
        
                // Sort months in chronological order
                const sortedMonths = months
                    .map(monthYear => {
                        const [month, year] = monthYear.split('/');
                        return { month: parseInt(month), year: parseInt(year), monthYear };
                    })
                    .sort((a, b) => new Date(a.year, a.month - 1) - new Date(b.year, b.month - 1))  // Sort chronologically
                    .map(item => item.monthYear);
        
                // Reorganize data to sorted months
                const sortedContaminationRates = sortedMonths.map(monthYear => contaminationRates[months.indexOf(monthYear)]);
                const sortedComplianceRates = sortedMonths.map(monthYear => complianceRates[months.indexOf(monthYear)]);
                const sortedTotalDraws = sortedMonths.map(monthYear => totalDraws[months.indexOf(monthYear)]);
                const sortedSteripathYesCounts = sortedMonths.map(monthYear => steripathYesCounts[months.indexOf(monthYear)]);
        
                // Set Chart Data for Contamination Rate (Blended Contamination Rate)
                setChartDataContamination({
                    labels: sortedMonths,
                    datasets: [
                        {
                            label: 'Blended Contamination Rate (%)',
                            data: sortedContaminationRates,
                            backgroundColor: 'rgba(255, 99, 132, 0.6)',
                            borderColor: 'rgba(255, 99, 132, 1)',
                            borderWidth: 1,
                        },
                    ],
                    averageRate: averageContaminationRate.toFixed(2),  // Display average contamination rate across all months
                });
        
                // Set Chart Data for Compliance Rate
                setChartData({
                    labels: sortedMonths,
                    datasets: [
                        {
                            label: 'Compliance Rate',
                            data: sortedComplianceRates,
                            backgroundColor: 'rgba(75, 192, 192, 0.6)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                        },
                    ],
                });
        
                // Set Chart Data for Total Draws vs Steripath YES
                setChartDataTotalDraws({
                    labels: sortedMonths,
                    datasets: [
                        {
                            label: 'Total Draws',
                            data: sortedTotalDraws,
                            backgroundColor: 'rgba(153, 102, 255, 0.6)',
                            borderColor: 'rgba(153, 102, 255, 1)',
                            borderWidth: 1,
                        },
                        {
                            label: 'Steripath YES',
                            data: sortedSteripathYesCounts,
                            backgroundColor: 'rgba(255, 159, 64, 0.6)',
                            borderColor: 'rgba(255, 159, 64, 1)',
                            borderWidth: 1,
                        },
                    ],
                });
            },
        });
    };

    const toggleRawContent = () => {
        setShowRawContent(prev => !prev);
    };

    const resetData = () => {
        setCsvContent('');
        setChartData(null);
        setChartDataTotalDraws(null);
        setChartDataContamination(null);
        setShowRawContent(false);
        setUploadStatus('Upload CSV File');
        setSelectedChart('complianceRate');  // Reset to default chart
        localStorage.removeItem('csvContent');
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Compliance Rate and Charts', 20, 20);
    
        // Add the Compliance Rate Chart if it exists
        if (chartData && chartData.labels && chartData.labels.length > 0) {
            const chartCanvas = document.getElementById('complianceRateChart');
            if (chartCanvas) {
                const chartImage = chartCanvas.toDataURL('image/png');
                doc.addImage(chartImage, 'PNG', 20, 40, 180, 160); // Position and size as needed
                doc.addPage();  // Start a new page for the next chart
            }
        }
    
        // Add the Total Draws vs Steripath YES chart if it exists
        if (chartDataTotalDraws && chartDataTotalDraws.labels && chartDataTotalDraws.labels.length > 0) {
            const chartCanvas2 = document.getElementById('totalDrawsChart');
            if (chartCanvas2) {
                const chartImage2 = chartCanvas2.toDataURL('image/png');
                doc.addImage(chartImage2, 'PNG', 20, 40, 180, 160);
                doc.addPage();  // Start a new page for the next chart
            }
        }
    
        // Add the Blended Contamination Rate chart if it exists
        if (chartDataContamination && chartDataContamination.labels && chartDataContamination.labels.length > 0) {
            const chartCanvas3 = document.getElementById('contaminationRateChart');
            if (chartCanvas3) {
                const chartImage3 = chartCanvas3.toDataURL('image/png');
                doc.addImage(chartImage3, 'PNG', 20, 40, 180, 160);
            }
        }
    
        // Save the PDF
        doc.save('page-content.pdf');
    };

    const isAdmin = userInfo?.roles?.includes('Admin');

    return (
        <div>
            <h2 style={{ color: 'black' }}>{uploadStatus}</h2>
            {!csvContent && (
    <div
        style={{
            width: 'auto',                       // Remove the full width calculation and use 'auto' to fit the text
            maxWidth: '600px',                   // Set a maximum width to limit the container size
            height: 'calc(20vh)',                // Maintain the height calculation
            padding: '10px',                     // Add padding for some space around the text
            margin: '5vh auto',                  // Center the box with vertical margin
            textAlign: 'center',
            lineHeight: 'normal',                // Adjust the line height to fit the text
            color: '#aaa',
            border: '2px dashed #007bff',        // Keep the dashed border
            borderRadius: '10px',
            backgroundColor: '#0842A6',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
    >
        Drag and drop a CSV file here
    </div>
)}
            {csvContent && (
                <div>
                    {isAdmin && (
                        <button className="App-button" onClick={toggleRawContent}>
                            {showRawContent ? 'Hide Raw CSV Content' : 'Show Raw CSV Content'}
                        </button>
                    )}
                    {showRawContent && (
                        <div>
                            <h3>Raw CSV Content:</h3>
                            <pre>{csvContent}</pre>
                        </div>
                    )}
                    <div style={{ marginBottom: '20px' }}>
    <h3 style={{ color: 'black' }}>Select Chart:</h3>
    <select
        className='App-button'
        value={selectedChart}
        onChange={(e) => setSelectedChart(e.target.value)}
    >
        <option value="complianceRate">Compliance Rate</option>
        <option value="totalDraws">Total Draws</option>
        <option value="contaminationRate">Contamination Rate</option>
    </select>
</div>

{selectedChart === 'complianceRate' && chartData && chartData.labels && chartData.labels.length > 0 && (
    <div style={{ margin: 'auto', textAlign: 'center', maxWidth: '50%' }}>
        <h3 style={{ color: 'black' }}>Compliance Rate by Collection Date:</h3>
        <Bar
            id="complianceRateChart"
            data={chartData}
            options={{
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Collection Date',
                            color: 'black',  // Title color for x-axis
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Compliance Rate (%)',
                            color: 'black',  // Title color for y-axis
                        },
                    },
                },
            }}
        />
    </div>
)}

{selectedChart === 'totalDraws' && chartDataTotalDraws && chartDataTotalDraws.labels && (
    <div style={{ margin: 'auto', textAlign: 'center', maxWidth: '50%' }}>
        <h3 style={{ color: 'black' }}>Total Draws vs Steripath YES (Grouped by Month):</h3>
        <Bar
            id="totalDrawsChart"
            data={chartDataTotalDraws}
            options={{
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Month/Year',
                            color: 'black',  // Title color for x-axis
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Total Counts',
                            color: 'black',  // Title color for y-axis
                        },
                    },
                },
            }}
        />
    </div>
)}

{selectedChart === 'contaminationRate' && chartDataContamination && chartDataContamination.labels && (
    <div style={{ margin: 'auto', textAlign: 'center', maxWidth: '50%' }}>
        <h3 style={{ color: 'black' }}>Blended Contamination Rate:</h3>
        <h4 style={{ color: 'black' }}>Average Contamination Rate: {chartDataContamination.averageRate}%</h4>
        <Bar
            id="contaminationRateChart"
            data={chartDataContamination}
            options={{
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Month/Year',
                            color: 'black',  // Title color for x-axis
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Contamination Rate (%)',
                            color: 'black',  // Title color for y-axis
                        },
                        min: 0,
                        max: 3,  // Set maximum value of the y-axis to 3.00%
                    },
                },
            }}
        />
    </div>
)}
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <button className="App-button" onClick={resetData}>
                            Reset
                        </button>
                        <button className="App-button" onClick={downloadPDF}>
                            Download PDF
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ComponentA;