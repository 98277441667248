import React, { useState, useEffect, useCallback } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import Papa from 'papaparse';
import { jsPDF } from 'jspdf';
import ChartAnnotation from 'chartjs-plugin-annotation';
import { BlobServiceClient } from '@azure/storage-blob';

// Register all necessary Chart.js components
Chart.register(...registerables, ChartAnnotation);

function BenchmarkPage({ userInfo }) {
    const [csvContent, setCsvContent] = useState('');
    const [chartData, setChartData] = useState(null);
    const [chartDataTotalDraws, setChartDataTotalDraws] = useState(null);
    const [chartDataContamination, setChartDataContamination] = useState(null);
    const [showRawContent, setShowRawContent] = useState(false);
    const [selectedChart, setSelectedChart] = useState(''); // Set initial state to '' for "--Select--"
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileList, setFileList] = useState([]);

    const storageAccountName = 'marwadata';
    const containerName = 'map';
    const sasToken = 'sp=racwl&st=2024-12-10T21:41:42Z&se=2025-12-11T05:41:42Z&spr=https&sv=2022-11-02&sr=c&sig=hkf%2Blr73gfauZ7QFhzpiXbeSil83AYE9YPmS7KFbpyg%3D';

    useEffect(() => {
        const containerClient = new BlobServiceClient(
            `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
        ).getContainerClient(containerName);
    
        const fetchFiles = async () => {
            let fileNames = [];
            for await (const blob of containerClient.listBlobsFlat()) {
                fileNames.push(blob.name);
            }
            setFileList(fileNames); 
        };
    
        fetchFiles().catch((error) => {
            console.error("Error fetching files from blob storage:", error);
        });
    }, []);

    const fetchAndParseCSV = useCallback((fileName) => {
        const containerClient = new BlobServiceClient(
            `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
        ).getContainerClient(containerName);
    
        const blobClient = containerClient.getBlobClient(fileName);
        blobClient.download().then(async (response) => {
            const blob = await response.blobBody;
            const reader = new FileReader();
            
            reader.onloadend = () => {
                const csv = reader.result;
                setCsvContent(csv); 
                generateComplianceRateByDate(csv); 
            };
            
            reader.onerror = (error) => {
                alert(`Error reading file: ${error.message}`);
            };
    
            reader.readAsText(blob);
        }).catch((error) => {
            alert(`Error fetching file: ${error.message}`);
        });
    }, [storageAccountName, containerName, sasToken]);

    useEffect(() => {
        if (selectedFile) {
            fetchAndParseCSV(selectedFile);
        }
    }, [selectedFile, fetchAndParseCSV]);

    const generateComplianceRateByDate = (csv) => {
        Papa.parse(csv, {
            header: true,
            complete: (results) => {
                const data = results.data;
                const groupedData = data.reduce((acc, row) => {
                    const date = row['Collection Date'];
                    const dateObj = new Date(date);
                    const monthYear = `${String(dateObj.getMonth() + 1).padStart(2, '0')}/${dateObj.getFullYear()}`;
                    if (!acc[monthYear]) {
                        acc[monthYear] = [];
                    }
                    acc[monthYear].push(row);
                    return acc;
                }, {});

                const months = [];
                const complianceRates = [];
                const contaminationRates = [];
                const totalDraws = [];
                const steripathYesCounts = [];
                let totalContaminants = 0;
                let totalTotalDraws = 0;

                Object.keys(groupedData).forEach((monthYear) => {
                    const monthData = groupedData[monthYear];
                    const totalCount = monthData.reduce((acc, row) => {
                        const count = parseInt(row['Count']) || 0;
                        return acc + count;
                    }, 0);

                    const steripathYesCount = monthData.reduce((acc, row) => acc + parseInt(row['Steripath YES'] || 0), 0);
                    const contaminantsCount = monthData.reduce((acc, row) => {
                        const contaminant = row['Contaminant ? ']?.toLowerCase() === 'yes' || row['Contaminant ? '] === '1';
                        return contaminant ? acc + 1 : acc;
                    }, 0);

                    const contaminationRate = totalCount > 0 ? (contaminantsCount / totalCount) * 100 : 0;
                    const complianceRate = totalCount > 0 ? (steripathYesCount / totalCount) * 100 : 0;

                    months.push(monthYear);
                    contaminationRates.push(contaminationRate);
                    complianceRates.push(complianceRate);
                    totalDraws.push(totalCount);
                    steripathYesCounts.push(steripathYesCount);

                    totalContaminants += contaminantsCount;
                    totalTotalDraws += totalCount;
                });

                const averageContaminationRate = totalTotalDraws > 0 ? (totalContaminants / totalTotalDraws) * 100 : 0;

                const sortedMonths = months
                    .map(monthYear => {
                        const [month, year] = monthYear.split('/');
                        return { month: parseInt(month), year: parseInt(year), monthYear };
                    })
                    .sort((a, b) => new Date(a.year, a.month - 1) - new Date(b.year, b.month - 1))
                    .map(item => item.monthYear);

                const sortedContaminationRates = sortedMonths.map(monthYear => contaminationRates[months.indexOf(monthYear)]);
                const sortedComplianceRates = sortedMonths.map(monthYear => complianceRates[months.indexOf(monthYear)]);
                const sortedTotalDraws = sortedMonths.map(monthYear => totalDraws[months.indexOf(monthYear)]);
                const sortedSteripathYesCounts = sortedMonths.map(monthYear => steripathYesCounts[months.indexOf(monthYear)]);

                setChartDataContamination({
                    labels: sortedMonths,
                    datasets: [
                        {
                            label: 'Blended Contamination Rate (%)',
                            data: sortedContaminationRates,
                            backgroundColor: 'rgba(255, 99, 132, 0.6)',
                            borderColor: 'rgba(255, 99, 132, 1)',
                            borderWidth: 1,
                        },
                    ],
                    averageRate: averageContaminationRate.toFixed(2),
                });

                setChartData({
                    labels: sortedMonths,
                    datasets: [
                        {
                            label: 'Compliance Rate',
                            data: sortedComplianceRates,
                            backgroundColor: 'rgba(75, 192, 192, 0.6)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                        },
                    ],
                });

                setChartDataTotalDraws({
                    labels: sortedMonths,
                    datasets: [
                        {
                            label: 'Total Draws',
                            data: sortedTotalDraws,
                            backgroundColor: 'rgba(153, 102, 255, 0.6)',
                            borderColor: 'rgba(153, 102, 255, 1)',
                            borderWidth: 1,
                        },
                        {
                            label: 'Steripath YES',
                            data: sortedSteripathYesCounts,
                            backgroundColor: 'rgba(255, 159, 64, 0.6)',
                            borderColor: 'rgba(255, 159, 64, 1)',
                            borderWidth: 1,
                        },
                    ],
                });
            },
        });
    };

    const toggleRawContent = () => {
        setShowRawContent(prev => !prev);
    };

    const resetData = () => {
        setCsvContent('');                    // Clear CSV content
        setChartData(null);                    // Clear Compliance Rate chart
        setChartDataTotalDraws(null);          // Clear Total Draws vs Steripath YES chart
        setChartDataContamination(null);       // Clear Contamination Rate chart
        setShowRawContent(false);              // Hide raw content view
        setSelectedChart('');                  // Reset chart selection to "--Select--"
        setSelectedFile(null);                 // Reset file selection to "--Select--"
        localStorage.removeItem('csvContent'); // Clear localStorage if needed
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Compliance Rate and Charts', 20, 20);

        if (chartData && chartData.labels && chartData.labels.length > 0) {
            const chartCanvas = document.getElementById('complianceRateChart');
            if (chartCanvas) {
                const chartImage = chartCanvas.toDataURL('image/png');
                doc.addImage(chartImage, 'PNG', 20, 40, 180, 160);
                doc.addPage();
            }
        }

        if (chartDataTotalDraws && chartDataTotalDraws.labels && chartDataTotalDraws.labels.length > 0) {
            const chartCanvas2 = document.getElementById('totalDrawsChart');
            if (chartCanvas2) {
                const chartImage2 = chartCanvas2.toDataURL('image/png');
                doc.addImage(chartImage2, 'PNG', 20, 40, 180, 160);
            }
        }

        doc.save('chart_report.pdf');
    };

    return (
        <div>
            <h1 style={{ color: 'black' }}>Dashboard</h1>
            <div>
                <label style={{ color: 'black' }}> Select CSV File: </label>
                <select className="App-button"
                    value={selectedFile || ''}
                    onChange={(e) => setSelectedFile(e.target.value)}
                >
                    <option value="">--Select--</option>
                    {fileList.map((fileName, index) => (
                        <option key={index} value={fileName}>{fileName}</option>
                    ))}
                </select>
            </div>

            <div>
                <label style={{ color: 'black' }}> Select Chart: </label>
                <select className="App-button"
                    value={selectedChart || ''}
                    onChange={(e) => setSelectedChart(e.target.value)}
                >
                    <option value="">--Select--</option>
                    <option value="complianceRate">Compliance Rate</option>
                    <option value="contaminationRate">Contamination Rate</option>
                    <option value="totalDraws">Total Draws vs Steripath YES</option>
                </select>
            </div>

            <button className="App-button" onClick={toggleRawContent}>
                {showRawContent ? 'Hide Raw Content' : 'Show Raw Content'}
            </button>

            {showRawContent && <pre>{csvContent}</pre>}

            <button className="App-button" onClick={resetData}>Reset Data</button>
            <button className="App-button" onClick={downloadPDF}>Download PDF</button>

            {selectedChart === 'complianceRate' && chartData && (
                <div>
                    <h2>Compliance Rate Chart</h2>
                    <Bar id="complianceRateChart" data={chartData} />
                </div>
            )}

            {selectedChart === 'contaminationRate' && chartDataContamination && (
                <div>
                    <h2>Contamination Rate Chart</h2>
                    <Bar id="contaminationRateChart" data={chartDataContamination} />
                </div>
            )}

            {selectedChart === 'totalDraws' && chartDataTotalDraws && (
                <div>
                    <h2>Total Draws vs Steripath YES Chart</h2>
                    <Bar id="totalDrawsChart" data={chartDataTotalDraws} />
                </div>
            )}
        </div>
    );
}

export default BenchmarkPage;